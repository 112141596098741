




















































































































import {Component, Vue} from 'vue-property-decorator';
import {EnvProvider, getFullDate} from '@/utilities';
import { ClaimStatusEnum, IClaimDetails } from '@/interfaces';
import Document from '@/components/Document.vue';
import Backlink from '@/components/Backlink.vue';
import ClaimAcceptModal from '@/components/Modals/ClaimAccept.modal.vue';
import ClaimDetailsAcceptDate from '@/pages/Claim/Claim.details.acceptDate.vue';
import {i18n} from '@/main';
import {LocaleService} from '@/utilities/locale';

@Component({
  metaInfo() {
    return {
      title: i18n.tc('meta.claim.title'),
      meta: [{
        name: 'description',
        content: i18n.tc('meta.claim.description')
      }]
    };
  },
  components: {
    Document,
    Backlink,
    ClaimAcceptModal,
    ClaimDetailsAcceptDate
  }
})
export default class ClaimDetails extends Vue {
  private ClaimStatusEnum = ClaimStatusEnum;
  private getFullDate = getFullDate;

  private acceptClaimModal = false;

  private created() {
    this.$store.dispatch('getDictionary', 'claim-types');
    this.$store.dispatch('getDictionary', 'damaged-places');
    this.$store.dispatch('getDictionary', 'damage-types');
    this.$store.dispatch('getClaim', this.$route.params.id);
  }

  private get getClaimDetails(): IClaimDetails {
    return this.$store.getters.getClaim;
  }

  private get getLang() {
    return this.$store.getters.language;
  }
  private get getDamagePlace() {
    return this.$store.getters.getDictionary('damaged-places')[this.getClaimDetails.damagePlace][this.getLang];
  }

  private get getClaimTypes() {
    return Object.keys(this.$store.getters.getDictionary('claim-types')).length && this.getClaimDetails.type
      ? this.$store.getters.getDictionary('claim-types')[this.getClaimDetails.type][this.getLang]
      : '';
  }

  private get getDamageTypes() {
    return this.getClaimDetails.damageTypes
      ? this.getClaimDetails.damageTypes.map(
        (type) => this.$store.getters.getDictionary('damage-types')[type][this.getLang]
      )
      : [];
  }

  private get formatRefNumber() {
    return (refNumber) => '000000'.slice(String(refNumber).length) + refNumber;
  }

  private acceptClaim(): void {
    this.$store.dispatch('acceptClaim', this.$route.params.id)
      .then(() => {
        this.acceptClaimModal = true;
      });
  }

  private payNow(): void {
    const checkoutLink = LocaleService.replaceDomain(EnvProvider('URL_CHECKOUT'));
    const { language } = this.$store.getters;
    window.open(`${checkoutLink}/${language}/claim-payment/${this.getClaimDetails.paymentToken}`, '_blank');
    this.acceptClaimModal = false;
    this.$router.push({ name: 'claim' });
  }

  private formatCurrency(amount, currency = 'EUR') {
    return this.$options.filters?.[currency](amount) ?? amount;
  }
}
